<template>
    <div class="account-login">
        <el-form ref="form" :model="form" label-width="80px">
            <el-form-item label="账号">
                <el-input v-model="form.account"></el-input>
            </el-form-item>
            <el-form-item label="密码">
                <el-input v-model="form.password"></el-input>
            </el-form-item>
            <el-form-item label="验证码">
                <el-input v-model="form.captcha"></el-input>
                <el-image
                        style="width: 100px; height: 100px"
                        :src="captchaUrl"
                        fit="contain"></el-image>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" @click="onSubmit">登录</el-button>
                <el-button @click="go">注册</el-button>
                <el-button @click="getCaptcha">获取验证码</el-button>
            </el-form-item>
        </el-form>
    </div>
</template>

<script>
    import { UserService } from "../../../api/user";
    import { getLastPage, setToken, setUserData } from "../../../utils/storageUtils";
    import { captcha } from '../../../api/common'
    import { mapActions } from 'vuex'
    export default {
        name: "login-index",
        data() {
            return {
                captchaUrl: '',
                form: {
                    account: '',
                    password: '',
                    captcha: ''
                }
            }
        },
        methods: {
            ...mapActions(['getUserInfo']),
            getCaptcha() {
                captcha().then(res => {
                    // // console.log('res', res)
                    this.captchaUrl = res.data
                })
            },
            go(path) {
                this.$router.push(path)
            },
            onSubmit() {
                UserService.login(this.form).then(async res => {
                    if (res.code) {
                        setToken(res.data.token)
                        this.getUserInfo().then(res => {
                            this.go('/')
                        })
                    } else {
                        this.$message.error(res.msg)
                        this.getCaptcha()
                    }
                })
            }
        },
        mounted() {
            this.getCaptcha()
        }
    }
</script>

<style scoped lang="scss">

</style>